<template>
  <div class="container position-fixed top-50 start-50 translate-middle h-75" v-if="terminal.isShow">
    <div class="row bg-black">
      <div class="col-2">
        <button class="btn" v-on:click="close">
          <span class="fa fa-window-close text-danger" ></span>
        </button>
        <button class="btn" v-on:click="close">
          <span class="fa fa-window-minimize text-warning" v-on:click="hide"></span>
        </button>
        <button class="btn" v-on:click="close">
          <span class="fa fa-window-maximize text-success"></span>
        </button>
      </div>
      <div class="col-8 text-white text-center">
        <span class="">Server Terminal</span>
      </div>
    </div>
    <div class="row bg-black h-75 overflow-auto" id="messages">
      <div class="col-12 pt-5 pb-1">
        <div v-for="message in messages" v-bind:key="message" class="text-white">
          <p>{{message}}</p>
        </div>
      </div>
    </div>

    <div class="row bg-black">
      <div class="col-12">
        <span class="text-white">{{ directory.join("") }}</span>

        <input type="text" class="border-0 text-white me-auto w-75 ms-3 bg-black"
               v-on:change="submitCommand" v-model="command" ref="command" />
      </div>
    </div>
  </div>
</template>

<script>
import {api} from "../services/api";

export default {
  name: "Terminal",
  props:{
    terminal:{
      show:false
    }
  },
  data() {
    return{
      messages:[],
      command:"",
      directory:["/"]
    }
  },
  methods:{
    focusInput() {
      this.$refs.command.focus();
    },
    close() {
      this.hide();
      this.directory = ["/"];
      this.command = "";
    },
    hide() {
      let t = this.terminal;
      t.isShow = false;
    },
    async submitCommand() {
      let cmd = this.command+"";
      this.command = "";
      if(cmd === "clear") {
        this.messages = [];
      }
      else {
        this.messages.push(cmd);
        let res = await api.commands.post("",{
          command:cmd,
          directory:this.directory.join("")
        });
        this.messages.push(res.message);
        if(cmd.startsWith("cd ") && !res.message) {
          cmd.split(" ")[1].split("/").forEach(x => {
            if(x) {
              if(x === "..")
                this.directory.pop();
              else
                this.directory.push(x + "/");
            }
          })

        }
        setTimeout(() => {
          let element = document.getElementById("messages");
          element.scrollTop = element.scrollHeight;
        },10);
      }
    }
  }
}
</script>
<style scoped>
btn:focus, a:focus, input:focus
{
  outline: none;
}
p {
  white-space: pre-wrap;
}
</style>